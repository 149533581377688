import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import Routes from './routes/routes';
import LoadingScreen from "./components/Atoms/LoadingScreen/LoadingScreen";
import HelperAction from "./redux/reducers/helper/actions";
import i18n from "./utils/i18n";
import { useDispatch } from "redux-react-hook";
import setUpInterceptor from "./utils/interceptor";
import AuthService from "./services/AuthService";
import UserAction from "./redux/reducers/user/actions";
import * as dotenv from 'dotenv';
import 'moment/locale/es';
import CountryService from './services/CountryService';
import UtilService from "./services/UtilService";
import Country from "./models/Country";
import {DEFAULT_LANGUAGE} from "./config/constants";

import 'react-simple-hook-modal/dist/styles.css';
import './assets/styles/global.scss';
import './App.scss';

const App = () => {
	const store = useStore();
	const dispatch = useDispatch();
	const [loadingInitValues, setLoadingInitValues] = useState(true);
	const authUser = useSelector((state: any) => state.user);
	const { localCountry } = useSelector((state: any) => state.helperReducer);
	dotenv.config({ path: '../.env' });

	setUpInterceptor(store);

	//Starting the states
	useEffect(() => {
		const countryFromApiResponse = async () => {
			const availableCountriesResponse = await CountryService.getAvailableCountries();
			let countriesAux = [];
			let localCountryAux: Country | undefined = undefined;

			if (availableCountriesResponse.success) {
				countriesAux = availableCountriesResponse.data;

			}else{
				console.error(availableCountriesResponse.error);
			}
			dispatch(HelperAction.save({ countries: countriesAux, localCountry: localCountry }));

			if (!localCountry?.code) {
				const localCountryCode = await UtilService.getLocalCountryFromApi();
				localCountryAux =  countriesAux.find((country: Country) => country.code.toUpperCase() === localCountryCode.toUpperCase());
				dispatch(HelperAction.save({ countries: countriesAux, localCountry: localCountryAux as Country }));
			}

			if (authUser.id === 0) {
				await i18n.changeLanguage(localCountryAux?.language || DEFAULT_LANGUAGE);
			}
			else {
				await i18n.changeLanguage(authUser.language);
			}
		}

		const verifyUserSession = async () => {

			const user = await AuthService.verifyUserSession(store);

			if(user.success)
			{
				dispatch(UserAction.save(user.data));
			} else {
				dispatch(UserAction.remove());
			}
		}

		const init = async () => {
			await verifyUserSession();
			await countryFromApiResponse();

			setLoadingInitValues(false);
		};

		init();
	}, [dispatch, localCountry, store]);

	return (
		<>
			{
				!loadingInitValues ? <Routes auth={authUser} /> : <LoadingScreen />
			}
		</>
	);
};

export default App;
