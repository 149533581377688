import {CircularProgress} from "@mui/material";
import TouchTaskImg from "../../../res/img/logo.png";
import './LoadingSpin.scss';
import {useEffect} from "react";

interface ILoadingSpin {
    loading: boolean,
    loadingtext?: string
};

const LoadingSpin = ({ loading, loadingtext = '' }: ILoadingSpin): JSX.Element => {

    useEffect(() => {

        if(loading) {
            document.body.classList.add('stop-scrolling');
        }
        else {
            document.body.classList.remove('stop-scrolling');
        }

        return () => {
            document.body.classList.remove('stop-scrolling');
        };

    }, [ loading ]);

    return (
        <>
            {
                loading
                    && (
                        <div className="loading_spin__container">
                            <img className="loading_spin_logo" src={TouchTaskImg} alt="TouchTask logo" />
                            <CircularProgress />
                            {
                                loadingtext && (
                                    <div className="mt-30 text__main" style={{ textAlign: 'center', maxWidth: '200px' }}>{loadingtext}</div>
                                )
                            }
                        </div>
                    )
            }
        </>
    );
};

export default LoadingSpin;