import { Navbar } from '../../Organisms/Navbar/Navbar';
import LoadingSpin from "../../Atoms/LoadingSpin/LoadingSpin";

import './App.scss';

interface AppInterface {
  children: React.ReactNode;
  loading?: boolean,
  loadingText?: string
};

const AppTemplate = ({
  children,
  loading,
  loadingText = ''
}: AppInterface) => {

  return !loading ? (
    <div className="app__main">
      <Navbar />
      <div className="app__content">
        {children}
      </div>
    </div>
  ) : (
      <>
          <LoadingSpin loading={loading} loadingtext={loadingText}/>
      </>
  );
};

export default AppTemplate;
