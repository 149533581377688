import './LoadingScreen.scss';
import {useTranslation} from "react-i18next";
import {getAppUrl} from "../../../utils/utils";
import CertificationLoadingSkeleton from "../../../scenes/Certifications/components/CertificationLoadingSkeleton";
import CertificationProcessLoadingSkeleton
    from "../../../scenes/CertificationProcess/components/CertificationProcessLoadingSkeleton";
import CertificationModuleLoadingSkeleton
    from "../../../scenes/CertificationModule/components/CertificationModuleLoadingSkeleton";
import CertificationQuizLoadingSkeleton
    from "../../../scenes/CertificationQuiz/components/CertificationQuizLoadingSkeleton";
import MyContractLoadingSkeleton from "../../../scenes/MyContract/components/MyContractLoadingSkeleton";
import IdentityVerificationLoadingSkeleton
    from "../../../scenes/IdentityVerification/components/IdentityVerificationLoadingSkeleton";
import ContractSigningLoadingSkeleton from "../../../scenes/ContractSigning/components/ContractSigningLoadingSkeleton";

const LoadingScreen = () => {
    const { t } = useTranslation();
    const BASE_APP_URL = getAppUrl();
    const URL = window.location.href;

    const ConditionalLoadingScreen = (): JSX.Element => {
        let conditionalUrl = `${BASE_APP_URL}`

        if(URL.toLowerCase() === `${conditionalUrl}/certifications`.toLowerCase() || URL.toLowerCase() === `${conditionalUrl}/certifications/`.toLowerCase()) {
            return <CertificationLoadingSkeleton />;
        }
        else if(URL.toLowerCase().startsWith(`${conditionalUrl}/certifications`.toLowerCase())) {
            return <CertificationProcessLoadingSkeleton />;
        }
        else if(URL.toLowerCase().startsWith(`${conditionalUrl}/certification-modules`.toLowerCase())) {
            return <CertificationModuleLoadingSkeleton />;
        }
        else if(URL.toLowerCase().startsWith(`${conditionalUrl}/certification-quizzes`.toLowerCase())) {
            return <CertificationQuizLoadingSkeleton />;
        }
        else if(URL.toLowerCase().startsWith(`${conditionalUrl}/my-contract/identity-verification`.toLowerCase())) {
            return <IdentityVerificationLoadingSkeleton />;
        }
        else if(URL.toLowerCase().startsWith(`${conditionalUrl}/my-contract/contract-signing`.toLowerCase())) {
            return <ContractSigningLoadingSkeleton />;
        }
        else if(URL.toLowerCase().startsWith(`${conditionalUrl}/my-contract`.toLowerCase())) {
            return <MyContractLoadingSkeleton />;
        }
        else {
            return <div>Loading...</div>;
        }
    };


    return (
        <ConditionalLoadingScreen />
    );
};

export default LoadingScreen;
