import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import LoadingScreen from "../components/Atoms/LoadingScreen/LoadingScreen";
import PropTypes from "prop-types";
import { ModalProvider } from "react-simple-hook-modal";
import { ThemeProvider } from '@material-ui/styles';
import themeConfig from "../utils/themeConfig";
import LoadingSpin from "../components/Atoms/LoadingSpin/LoadingSpin";
import AlertMessageModal from "../components/Atoms/AlertMessageModal/AlertMessageModal";

export default function Routes({ auth }: any) {

  //Pages
  const NotFound = lazy(() => import('../scenes/NotFound'));
  const SignIn = lazy(() => import('../scenes/SignIn'));
  const SignUp = lazy(() => import('../scenes/SignUp'));
  const ActivateAccount = lazy(() => import('../scenes/ActivateAccount'));
  const SuccessfullySignUp = lazy(() => import('../scenes/SignUp/components/SuccessfullySignUp'));
  const RecoverPassword = lazy(() => import('../scenes/RecoverPassword'));
  const RestorePassword = lazy(() => import('../scenes/RestorePassword'));

  const Profile = lazy(() => import('../scenes/Profile'));

  const Home = lazy(() => import('../scenes/Home'));

  const Certifications = lazy(() => import('../scenes/Certifications'));
  const CertificationProcess = lazy(() => import('../scenes/CertificationProcess'));
  const CertificationModules = lazy(() => import('../scenes/CertificationModule'));
  const CertificationQuizzes = lazy(() => import('../scenes/CertificationQuiz'));

  const Bonus = lazy(() => import('../scenes/Bonus'));
  const BonusSection = lazy(() => import('../scenes/BonusSection'));

  const Payment = lazy(() => import('../scenes/Payment'));
  const PaymentDetail = lazy(() => import('../scenes/PaymentDetail'));

  const MyContract = lazy(() => import('../scenes/MyContract'));
  const IdentityVerification = lazy(() => import('../scenes/IdentityVerification'));
  const ContractSigning = lazy(() => import('../scenes/ContractSigning'));

  const MyTasks = lazy(() => import('../scenes/MyTasks'));
  const MyTaskDetails = lazy(() => import('../scenes/MyTaskDetails'));
  const QRCodeReader = lazy(() => import('../scenes/QRCodeReader'));

  const AvailableTasks = lazy(() => import('../scenes/AvailableTasks'));
  const AvailableBrands = lazy(() => import('../scenes/AvailableBrands'));
  const AvailableTasksDays = lazy(() => import('../scenes/AvailableTasksDays'));

  const QrValidate = lazy(() => import('../scenes/QrValidate'));

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <ThemeProvider theme={themeConfig}>
          <ModalProvider backdropClassName="modal-provider__main">
            <AlertMessageModal />
            <Switch>
              <Route exact path="/" >
                {auth.id === 0 ? <Redirect to="/sign-in" /> : <Redirect to="/home" />}
              </Route>

              <PublicRoute exact path="/sign-in" component={SignIn} />
              <PublicRoute exact path="/sign-up" component={SignUp} />
              <Route exact path="/activate-account/:token" component={ActivateAccount} />
              <Route exact path="/succesfully-sign-up" component={SuccessfullySignUp} />
              <Route exact path="/recover-password" component={RecoverPassword} />
              <Route exact path="/restore-password/:token" component={RestorePassword} />

              <Route exact path="/profile" component={Profile} />
              <Route exact path="/qr-validate" component={QrValidate} />
              <PrivateRoute exact path="/home" component={Home} />

              <Route exact path="/certifications" component={Certifications} />
              <Route exact path="/certifications/:categoryId" component={CertificationProcess} />
              <Route exact path="/certification-modules/:certificationId" component={CertificationModules} />
              <Route exact path="/certification-quizzes/:certificationModuleId" component={CertificationQuizzes} />

              <PrivateRoute exact path="/bonus" component={Bonus} />
              <PrivateRoute exact path="/bonus/:id" component={BonusSection} />

              <PrivateRoute exact path="/my-payments" component={Payment} />
              <PrivateRoute exact path="/my-payments/:id" component={PaymentDetail} />

              <PrivateRoute exact path="/my-contract" component={MyContract} />
              <PrivateRoute exact path="/my-contract/identity-verification" component={IdentityVerification} />
              <PrivateRoute exact path="/my-contract/contract-signing" component={ContractSigning} />

              <PrivateRoute exact path="/my-tasks" component={MyTasks} />
              <PrivateRoute exact path="/my-tasks/:id" component={MyTaskDetails} />
              <PrivateRoute exact path="/qr-code-reader/:id" component={QRCodeReader} />

              <PrivateRoute exact path="/available-tasks" component={AvailableTasks} />
              <PrivateRoute exact path="/available-tasks/:roleId" component={AvailableBrands} />
              <PrivateRoute exact path="/available-tasks/:roleId/:brandId" component={AvailableTasksDays} />

              <Route path="*" component={NotFound} />
            </Switch>
          </ModalProvider>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  );
}

Routes.propTypes = {
  auth: PropTypes.object.isRequired
};
