import api from "../utils/axios";
import ApiResponse from "../models/ApiResponse";
import User from "../models/User";
import {formattingAxiosResponseErrors} from "../utils/utils";
import {CertificateSubscription} from "../models/CertificateSubscription";
import Profile from "../models/Profile";
import City from "../models/City";

const AuthService = {
    verifyUserSession : async ( store : any) : Promise<ApiResponse> => {

        //Todo: check
        if(!store.getState().user.token)
        {
            return {
                success: false,
                error: "Don't have a token"
            };
        }

        try {
            const response = await api.get(`/auth/current-user`);
            const userData = response.data.data;
            let user: User | null = null;

            if(userData) {
                let profile: Profile | undefined = undefined;

                if(userData.profile) {

                    let city: City | undefined = undefined;

                    if(userData.profile.city) {
                        city = {
                            id: userData.profile.city.id,
                            regionId: userData.profile.city.regionId,
                            regionName: userData.profile.city.regionName,
                            provinceId: userData.profile.city.provinceId,
                            provinceName: userData.profile.city.provinceName,
                            cityId: userData.profile.city.cityId,
                            cityName: userData.profile.city.cityName
                        };

                        profile = {
                            id: userData.profile.id,
                            name: userData.profile.name,
                            lastname: userData.profile.lastname,
                            documentType: userData.profile.documentType,
                            documentNumber: userData.profile.documentNumber,
                            gender: userData.profile.gender,
                            phone: userData.profile.phone,
                            birthDate: userData.profile.birthDate,
                            nationality: userData.profile.nationality,
                            validatedInformationAt: userData.profile.validatedInformationAt,
                            city: city,
                            address: userData.profile.address
                        }
                    }
                }

                user = {
                    id: userData.id,
                    email: userData.email,
                    roleId: userData.roleId,
                    countryCode: userData.countryCode,
                    token: userData.token,
                    avatarBase64Encoded: userData.avatarS3Key,
                    profile: profile,
                };
            }

            return {
                success: true,
                data: user,
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);
        }
    },

    logout : async () : Promise<ApiResponse> => {
        try {
            const response = await api.post(`/auth/logout`);
            console.log(response);
            return {
                success: true
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);

        }
    },

    getFileByKey : async (key: string): Promise<string> => {
        const response = await api.post("/file", { key });
        return response.data.data?.base64Encoded || '';
    },

    getCurrentProfile: async (): Promise<ApiResponse> => {
        try {
            const response = await api.get(`/profile/current-user-profile`);
            const profileData = response.data.data;
            let profile: Profile | null = null;

            if(profileData) {
                let city: City | undefined = undefined;

                if(profileData.city) {
                    city = {
                        id: profileData.city.id,
                        regionId: profileData.city.regionId,
                        regionName: profileData.city.regionName,
                        provinceId: profileData.city.provinceId,
                        provinceName: profileData.city.provinceName,
                        cityId: profileData.city.cityId,
                        cityName: profileData.city.cityName
                    };

                    profile = {
                        id: profileData.id,
                        name: profileData.name,
                        lastname: profileData.lastname,
                        documentType: profileData.documentType,
                        documentNumber: profileData.documentNumber,
                        gender: profileData.gender,
                        phone: profileData.phone,
                        birthDate: profileData.birthDate,
                        nationality: profileData.nationality,
                        validatedInformationAt: profileData.validatedInformationAt,
                        city: city,
                        address: profileData.address
                    }
                }
            }

            return {
                success: true,
                data: profile
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);
        }
    },

    /*
        List of certificates to which a user is subscribed
     */
    getCertificateSubscriptionsByUserId: async (country: string, userId: number): Promise<ApiResponse> => {
        try {
            const response = await api.get(`/${country}/user/certificate/all/by/owner/find/${userId}`);

            const certificates: CertificateSubscription[] = response.data.data.map((record: any): CertificateSubscription => {
                return {
                    userCertificateId: record.id,
                    certificateId: record.origin.id,
                    userId: record.user.id,
                    passed: record.approved
                };
            });

            return {
                success: true,
                data: certificates
            };
        }catch (err)
        {
            return formattingAxiosResponseErrors(err);
        }
    },
};

export default AuthService;